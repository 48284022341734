<script>
  // works for all supported pre-processors as well
  import DOMPurify from 'dompurify'
  import Tooltip from '@moqomps/components/partials/tooltip'
  import ModalLicenseInfo from '@sign_up/components/modal_license_info'
  import FriendsReferralInfo from '@sign_up/components/friends_referral_info'
  import SignUpCampaignBadge from '@sign_up/components/sign_up_campaign_badge'
  import InfoBox from '@sign_up/components/info_box'
  import RequiredAcceptanceCheckboxes from '@sign_up/components/required_acceptance_checkboxes'
  import accountDetailsList from "@sign_up/mixins/account_details_list"
  import signUpStepsRouting from "@sign_up/mixins/sign_up_steps_routing"
  import Linkify from '../../users_profile/mixins/linkify'

  export default {
    mixins: [
      accountDetailsList,
      signUpStepsRouting,
      Linkify
    ],

    components: {
      Tooltip,
      FriendsReferralInfo,
      SignUpCampaignBadge,
      InfoBox,
      RequiredAcceptanceCheckboxes
    },

    data () {
      return {
        passwordStrengthAcceptable: false,
        allFieldsFilled: true,
        login_url: '/login',
        reset_password_url: '/redirect/reset-password',
        skippedFederatedLoginOptions: false
      }
    },

    created () {
      if (!!this.$currentUser) this.setPasswordStrenghtStatus({value: true})
    },

    computed: {
      showFederatedLoginOptions () {
        return !!this.federatedLoginOptions && !this.skippedFederatedLoginOptions && !this.$currentUser
      },

      signupRequirements () {
        if (this.$signUpSettings?.custom_signup_restrictions) {
          return DOMPurify.sanitize(this.$signUpSettings.custom_signup_restrictions)
        }
        if (this.minimumAgeRequirement) {
          return this.minimumAgeRequirementInfo
        }

        return null
      },
      requirePassword () {
        return !this.$currentUser || this.checkForMissingSubmitFields(['password']) || this.$signUpForm.record.require_password
      },

      requireLicenseNumber () {
        return this.$signUpSettings?.license_number_required
      },

      showLicenseInfoButton () {
        return this.$layout.provider_country === 'DE'
      },

      signUpCampaignInfo () {
        const sign_up_campaign_value = this.$signUpSettings?.sign_up_campaign_value?.formatted

        if (sign_up_campaign_value) {
          return this.$t('account_details.sign_up_campaign.badge', {
            campaign_value: sign_up_campaign_value
          })
        }

        return null
      },

      signUpCampaignDisclaimer () {
        return "<sup>*</sup>" + this.$signUpSettings?.sign_up_campaign_disclaimer
      },

      friendsReferral () {
        return this.$signUpSettings?.referral
      },

      friendsReferralInfo () {
        return this.$t('account_details.friends_referral_info', {
          referred_by: this.friendsReferral.referred_by,
          campaign_value: this.friendsReferral.campaign_value.formatted
        })
      },

      organizationInfo () {
        return this.$t('account_details.organization_info', {organization: this.$signUpSettings.organization.name})
      },

      family () {
        return this.$signUpSettings?.family
      },

      familyInfo () {
        let benefits = []
        if (this.$signUpSettings?.saved_signup_fee) {
          benefits.push(this.$t('account_details.family_infos.benefits.signup_fee', {
            signup_fee_amount: this.$signUpSettings.saved_signup_fee.formatted
          }))
        }

        if (this.$signUpSettings?.saved_subscription_costs) {
          benefits.push(this.$t('account_details.family_infos.benefits.subscription_fee', {
            subscription_fee_amount: this.$signUpSettings.saved_subscription_costs.formatted,
            subscription_recurrence: this.$t(`subscription_recurrence.adjective.${this.$signUpSettings.subscription_recurrence}`)
          }))
        }

        if (benefits.length == 0) {
          return this.$t('account_details.family_infos.without_benefit', {
            organizer: this.family?.organizer?.full_name
          })
        } else {
          return this.$t('account_details.family_infos.with_benefit', {
            organizer: this.family?.organizer?.full_name,
            benefits: benefits.join(this.$t('account_details.family_infos.benefits.last_word_connector'))
          })
        }
      },

      preventEmailEditing () {
        return !!this.$currentUser && this.$signUpForm.record?.require_email_change !== true
      },

      pageTitle () {
        if (this.$layout.in_migration) {
          return this.$t('account_details.page_title.migration')
        }
        if (!this.$signUpSettings?.subscription_costs && !this.$signUpSettings?.signup_fee) {
          return this.$t('account_details.page_title.free')
        }
        return this.$t('account_details.page_title.with_costs')
      },

      minimumAgeRequirement () {
        return this.$signUpSettings?.minimum_age_requirement
      },

      minimumAgeRequirementInfo () {
        return this.$t('account_details.age_verification_info', {age: this.minimumAgeRequirement})
      },

      requiredValidations () {
        let validate_options = this.SIGN_UP_FIELDS.ACCOUNT
        if (!this.requirePassword) {
          validate_options = validate_options.filter((value) => {
            return (value !== 'password')
          })
        }

        if (!this.requireLicenseNumber) {
          validate_options = validate_options.filter((value) => {
            return (value !== 'license_number')
          })
        }

        return validate_options
      },

      submitAllowed () {
        return !!(this.passwordStrengthAcceptable &&
          this.allFieldsFilled &&
          this.$refs.acceptance.requiredCheckboxesAccepted
        )
      },

      customEmailTakenError () {
        const error = this.$signUpForm.validationErrorReasons?.email
        if (error && error.indexOf('taken') !== -1) {
          this.$set(this.$signUpForm.validation, 'email', ['']) // delete the error coming from backend
          return true
        }

        return false
      },

      inMigration () {
        return this.$layout.in_migration
      },

      currentStep () {
        return this.signUpStepsCount ? '1' : null
      },

      newsletterConsentText () {
        return this.$signUpSettings?.newsletter_consent_text
      },

      skipNewsletter() {
        return this.$signUpSettings?.feature_skip_newsletter_for_signup
      }
    },

    methods: {
      postForm () {
        this.$signUpForm.record.validate_options = this.requiredValidations
        if (!this.personalDetailsMissing && !this.paymentDetailsRequired) {
          this.$signUpForm.record.submit = true
        }

        this.validateFormInput()

        if (this.submitAllowed) {
          this.$signUpForm.update()
        }
      },

      setPasswordStrenghtStatus (payload) {
        this.passwordStrengthAcceptable = payload.value
      },

      showLicenseInfo () {
        this.openModal(ModalLicenseInfo)
      },

      validateFormInput () {
        this.validateInputFields()
        this.validatePasswordFields()
        this.$refs.acceptance.validateRequiredCheckboxes()
      },

      validateInputFields () {
        let validationErrors = {}

        this.requiredValidations.forEach((field) => {
          if (!this.$signUpForm.record[field]) {
            validationErrors[field] = [this.$t('form.error.empty')]
          }
        })

        if (Object.keys(validationErrors).length) {
          this.allFieldsFilled = false
          this.$signUpForm.$emit('validation', validationErrors)
        } else {
          this.$set(this.$signUpForm, 'validation', {})
          this.allFieldsFilled = true
        }
      },

      validatePasswordFields () {
        if ( !this.passwordStrengthAcceptable ){
          this.$set(this.$signUpForm.validation, 'password',[''])
        } else {
          this.$delete(this.$signUpForm.validation, 'password')
        }
      },

      removeLoginTooltip (event) {
        if (event.target.tagName === 'FORM') return
        if (this.$refs.tooltip) this.$refs.tooltip.remove()
      }
    },

    watch: {
      '$signUpForm.record.password' (newValue, oldValue) {
        if (oldValue || newValue) { // avoid initial change from undefined to null
          this.validatePasswordFields()
          this.$set(this.$signUpForm.record, 'password_confirmation', newValue)
        }
      }
    }
  }
</script>


<template>
  <step-wrapper id='account_details' :page_title='pageTitle' :current_step="currentStep" :class="{'sign-up-campaign-active' : signUpCampaignInfo}" :hide_step_indicator="showFederatedLoginOptions">
    <template slot='side'>
      <h2 v-html='pageTitle' class='hide-for-small-only gutter-bottom'></h2>

      <info-box :title='pageTitle'>
        <p class='bold hide-for-small-only'>{{ $t('account_details.infobox.title') }}</p>

        <mq-list :items='listItems()' :checkmark='true' :color='secondaryColor'></mq-list>

        <mq-panel v-if='signupRequirements' icon='info' :icon_color='secondaryColor' class='column large-22 end white hide-for-small-only gutter-top'>
          <div v-html='signupRequirements'></div>
        </mq-panel>
      </info-box>

      <car-image class='hide-for-small-only'></car-image>
    </template>

    <template slot='main'>
      <portal v-if='!inMigration' to="after-login-button">
        <tooltip ref='tooltip' class='login-button-tooltip'>
          <span v-html="$t('account_details.login_tooltip')"></span>
        </tooltip>
      </portal>

      <sign-up-campaign-badge :info='signUpCampaignInfo'></sign-up-campaign-badge>

      <mq-panel v-if='signupRequirements' icon='info' :icon_color='secondaryColor' class='column large-22 end white show-for-small-only gutter-bottom'>
        <div v-html='signupRequirements'></div>
      </mq-panel>

      <friends-referral-info v-if='friendsReferral' :info='friendsReferralInfo' class='small-gutter-bottom'></friends-referral-info>

      <friends-referral-info v-if='family' :info='familyInfo' class='small-gutter-bottom'></friends-referral-info>

      <friends-referral-info v-if='registerAsOrganizationMember' :info='organizationInfo' class='small-gutter-bottom'></friends-referral-info>

      <div v-if="showFederatedLoginOptions" class="apply-additional-padding">
        <br>
        <mq-button as='link' class='float-center' size='medium' :color='primaryColor' :href='federatedLoginOptions.href'>
          {{ $t('federated_login_options.sign_up_via_sso', { sso_provider: federatedLoginOptions.name }) }}
        </mq-button>

        <br><br><hr><br><br>

        <mq-button as='link' class='float-center' size='medium' :color='secondaryColor' @click="skippedFederatedLoginOptions = true">
          {{ $t('federated_login_options.sign_up_via_email') }}
        </mq-button>
      </div>

      <form v-else class='input-section account-form' @submit.prevent='postForm' @click='removeLoginTooltip'>
        <mq-input-row class='remove-left-padding' v-if='preventEmailEditing'>
          <mq-input :form='$signUpForm' field='email' :disabled='true'></mq-input>
        </mq-input-row>

        <mq-input-row>
          <mq-input :form='$signUpForm' field='first_name'  size='medium-12'></mq-input>
          <mq-input :form='$signUpForm' field='last_name'  size='medium-12'></mq-input>
        </mq-input-row>

        <mq-input-row v-if='!preventEmailEditing'>
          <mq-input :form='$signUpForm' field='email'></mq-input>

          <i18n v-if='customEmailTakenError && !inMigration' path="form.error.email_taken.message" tag='div' class='email-taken-error column alert-color small'>
            <template #login_link>
              <a :href='login_url' style='color: #000'>{{ $t('form.error.email_taken.login_link') }}</a>
            </template>
            <template #reset_password_link>
              <a :href='reset_password_url' style='color: #000'>{{ $t('form.error.email_taken.reset_password_link') }}</a>
            </template>
          </i18n>
          <i18n v-if='customEmailTakenError && inMigration' path="form.error.email_taken.message_migration" tag='div' class='email-taken-error column alert-color small'></i18n>
        </mq-input-row>

        <mq-input-row v-if='requirePassword'>
          <mq-input-password :form='$signUpForm' field='password' @event='setPasswordStrenghtStatus'></mq-input-password>
        </mq-input-row>

        <mq-input-row v-if="requireLicenseNumber" class='license-info-input'>
          <mq-input :form='$signUpForm' field='license_number' :with_info='showLicenseInfoButton' @info-event='showLicenseInfo'></mq-input>
        </mq-input-row>

        <required-acceptance-checkboxes ref="acceptance" />

        <mq-input-row v-if="!skipNewsletter">
          <mq-checkbox :form='$signUpForm' field='newsletter_acceptance' class="newsletter-checkbox"><div class="small-gutter-bottom" v-html="linkifyText(newsletterConsentText.replace(/\n/g, '<br>'))"></div></mq-checkbox>
        </mq-input-row>


        <mq-input-row as='submit'>
          <mq-form-errors :field_filter='SIGN_UP_FIELDS.ACCOUNT'/>

          <div class='column text-center'>
            <mq-button size='small' :color='primaryColor' :is_loading='$signUpForm.isLoading'>
              {{ $t('buttons.next') }}
            </mq-button>
          </div>
        </mq-input-row>

        <mq-input-row v-if='signUpCampaignInfo' class='gutter-top'>
          <p class='text-medium text-gray' v-html="signUpCampaignDisclaimer"></p>
        </mq-input-row>
      </form>
    </template>
  </step-wrapper>
</template>


<style lang='scss'>
  @import '~@moqomps/style';

  .login-button-tooltip {
    position: absolute;
    right: 0;
    width: rem-calc(350);
    font-size: rem-calc(14);
    line-height: rem-calc(16);
    hyphens: auto;
    text-transform: none;
    white-space: normal;
    z-index: 10000;

    @include breakpoint(small only) {
      right: rem-calc(10);
      width: rem-calc(300);
      top: rem-calc(75);
    }

    .header--with-bg & {
      animation-name: fadeOutUp;
      animation-delay: 0ms;
      animation-duration: 500ms;
      animation-fill-mode: both;
    }
  }

  #account_details {

    form {
      @include breakpoint(small down) {
        padding-bottom: rem-calc(50);
      }
    }

    &.sign-up-campaign-active {
      margin-top: rem-calc(20);

      @include breakpoint(small only) {
        margin-top: 0;
      }

      .page-title {
        hyphens: auto;

        @include breakpoint(small down) {
          br {
            display: none;
          }
        }
      }
    }

    .sign_up_campaign {
      position: absolute;
      top: rem-calc(-50);
      right: 0;

      @include breakpoint(mediumlarge down) {
        margin-right: 0;
      }

      @include breakpoint(medium down) {
        right: rem-calc(-25);
      }

      @include breakpoint(small only) {
        position: relative;
        top: rem-calc(-30);
        right: 0;
        overflow: hidden;
        margin: 0 auto;
      }
    }

    .remove-left-padding {
      margin-left: -1em;

      @include breakpoint(small only) {
        margin-left: -1.6em;
      }
    }

    .email-taken-error {
      margin-top: rem-calc(-7);
    }
  }

  .newsletter-checkbox {
    max-height: 200px;
    overflow: auto;
    overflow-wrap: break-word;
  }
</style>
